.App {
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  width: 40vmin;
}

.login-text {
  color:#dcddde;
}

.input-field {
  border: none;
  background: #353e4f;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  color: #e1e1e1;
  font-size: 30px
}

code {
  background-color: rgb(27, 30, 36);
  border-radius: 4px;
  color: #c8bdbd;
  padding: 5px;
}

.login-button {
  margin-top: 20px;
  border: none;
  background: #3d7c51;
  font-weight: bold;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  letter-spacing: 2px;
  font-family: sans-serif;
  width: 200px;
  -webkit-transition: 1s all;
  transition: 1s all;
}

.login-button:hover {
  background: #419b63;
  -webkit-transition: 1s all;
  transition: 1s all;
}